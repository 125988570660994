import React from 'react'
import ConfirmModal from '../../components/common/modals/ConfirmModal'
import { Design } from '../../api/Designs/Types'
import httpClient from '../../api/httpClient'
import { User } from '../../api/Users/Types'

export interface Props {
  readonly currentUser?: User
  readonly design: Design
}

const DesignActionRequiredModal: React.FC<Props> = ({
  currentUser,
  design,
  ...otherModalProps
}) => {
  const editInCad = (): void => {
    if (currentUser) {
      window.open(`/machine-builder/${design.id}`)
    } else {
      window.location.href = '/users/sign_in'
    }
  }

  const submitForVentionReview = async (): Promise<void> => {
    try {
      const response = await httpClient.post(`/designs/${design.id}/obsolete_design_reviews`, {
        headers: { Accept: 'application/json' },
      })

      if (response.status === 200) {
        toastr.success(response.data?.notice, '', { preventDuplicates: false })
      }
    } catch (error) {
      const response = error.response

      if (response.status === 401) {
        window.location.href = '/users/sign_in'
      } else {
        toastr.error(response.data?.error, '', { preventDuplicates: false })
      }
    }
  }

  const notifyDesignOwners = async (): Promise<void> => {
    try {
      const response = await httpClient.post(
        `/designs/${design.id}/obsolete_design_purchase_notifications`,
        { headers: { Accept: 'application/json' } }
      )

      if (response.status === 200) {
        toastr.success(response.data?.notice, '', { preventDuplicates: false })
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/users/sign_in'
      }
    }
  }

  const okButtonLabel = design?.is_vention_verified
    ? 'Submit for Vention review'
    : 'Notify design owner'

  const isCurrentUserLeadDesigner = design?.lead_designer_id === currentUser?.id

  return (
    <ConfirmModal
      dismissAction={editInCad}
      confirmedAction={design.is_vention_verified ? submitForVentionReview : notifyDesignOwners}
      title='This design contains obsolete part(s)'
      message={
        <div>
          These parts need to be replaced before purchasing. Notify the design owner to replace the
          obsolete part(s) or edit the design.
        </div>
      }
      yesButtonClass='button-vention'
      okButtonLabel={okButtonLabel}
      hideOkButton={isCurrentUserLeadDesigner}
      dismissButtonLabel='Customize & edit design'
      {...otherModalProps}
    />
  )
}

export default DesignActionRequiredModal
