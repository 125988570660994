import React, { useState } from 'react'
import I18n from '../../helpers/I18n'
import Modal from 'react-modal'
import ModalOpen from '../common/modals/ModalOpen'
import { quickAddToCart } from '../../api/Cart/Service'
import ConfirmModal from '../common/modals/ConfirmModal'
import DesignCustomizationModal from '../../partials/designs/DesignCustomizationModal'
import { Design } from '../../api/Designs/Types'
import { appendQueryString } from '../../helpers/StringHelper'
import { User } from '../../api/Users/Types'
import DesignActionRequiredModal from './DesignActionRequiredModal'

export interface Props {
  readonly currentUser?: User
  readonly design: Design
  readonly addToCartButtonClass: string
  analyticId?: string
}

const DesignAddToCartButton: React.FC<Props> = ({
  currentUser,
  design,
  addToCartButtonClass,
  analyticId,
}) => {
  const [isOpenRequestDesignModalOpened, setIsOpenRequestDesignModalOpened] = useState(false)

  const openDesignModal = (): void => setIsOpenRequestDesignModalOpened(true)
  const closeDesignModal = (): void => setIsOpenRequestDesignModalOpened(false)

  const getQuantity = () => {
    const input: HTMLInputElement | null = document.querySelector(
      '.design-page-viewer-add-quantity input'
    )
    const quantity = Number(input?.value) || 1
    return quantity
  }

  const getIncludeMachineLogicPrograms = () => {
    const input: HTMLInputElement | null = document.querySelector(
      '#include_machine_logic_programs:checked'
    )
    const includeMachineLogicPrograms = Boolean(input?.value)
    return includeMachineLogicPrograms
  }

  const getIncludeRobotPartnerParts = () => {
    const input: HTMLInputElement | null = document.querySelector(
      '#include_robot_partner_parts:checked'
    )
    const includeRobotPartnerParts = Boolean(input?.value)
    return includeRobotPartnerParts
  }

  const addDesignToCart = (): void => {
    const url = appendQueryString(`/designs/${design.id}/buy`, {
      quantity: getQuantity(),
      include_machine_logic_programs: getIncludeMachineLogicPrograms(),
      include_robot_partner_parts: getIncludeRobotPartnerParts(),
    })
    quickAddToCart(null, url, 'Design')
  }

  if (design.is_vention_verified && design.has_obsolete_parts === false) {
    return (
      <button type='button' className={addToCartButtonClass} onClick={addDesignToCart}>
        {I18n.t('views.designs.show.add_to_cart')}
      </button>
    )
  }

  const addToCartModal = (
    <ConfirmModal
      dismissAction={addDesignToCart}
      confirmedAction={openDesignModal}
      title='Get a design review'
      message={
        <>
          <div>This public design has not been verified by a Vention expert.</div>
          <div>Would you like a complimentary design review?</div>
        </>
      }
      yesButtonClass='button-vention'
      okButtonLabel='Request Design Review'
      dismissButtonLabel='Skip & add to cart'
    />
  )

  const actionRequiredModal = (
    <DesignActionRequiredModal currentUser={currentUser} design={design} />
  )
  return (
    <>
      <ModalOpen
        buttonComponent={
          <button data-analytic-id={analyticId} className={addToCartButtonClass}>
            {I18n.t('views.designs.show.add_to_cart')}
          </button>
        }
        modalComponent={design.has_obsolete_parts ? actionRequiredModal : addToCartModal}
      />

      <Modal
        isOpen={isOpenRequestDesignModalOpened}
        onRequestClose={closeDesignModal}
        className='react-modal design-review-request'
        overlayClassName='react-modal-overlay'
        shouldCloseOnOverlayClick={true}
      >
        <div className='modal-content center-self'>
          <div className='modal-header'>
            <button
              type='button'
              onClick={closeDesignModal}
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true' className='icon-close'></span>
            </button>
            Schedule a Design Review
          </div>
          <DesignCustomizationModal closeModal={closeDesignModal} design={design} />
        </div>
      </Modal>
    </>
  )
}

export default DesignAddToCartButton
